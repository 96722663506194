<template>
  <v-container :fluid="$vuetify.breakpoint.md">
    <h3
      v-if="newOrders.length === 0 && activeOrders.length === 0 && archiveOrders.length === 0"
    >There are no orders for now</h3>
    <!-- section for new orders -->
    <v-row v-if="isChef && profile.settings">
      
      <v-col cols="7" sm4>
        <h3>Adjust Lead Time </h3>
        
        
      </v-col>
      <v-col cols="3" sm4>
        Need help? (877) 929-9006
   
      </v-col>
      <v-col cols="2" sm4>
   
        <router-link to='/order/list/v2.0'>New Order view</router-link>
      </v-col>
      <v-col cols="12" sm6>
        <v-slider
          @change="changeLeadTime"
          v-model="profile.settings.defaultLeadTime"
          :tick-labels="[0,5,10,15,20,25,30,35,40,45,50,55, 60]"
          :max="60"
          step="5"
          ticks="always"
          tick-size="4"
        ></v-slider>
      </v-col>
    </v-row>
    <v-row v-if=" newOrders.length > 0">
      <v-col cols="12">
        <h3>New Orders</h3>
        <order-items
          :orders="newOrders"
          :orderSource="orderSource"
          :mobile="$vuetify.breakpoint.xs"
          status="new"
          :isChef="isChef"
        />
      </v-col>
    </v-row>

    <v-row v-if="activeOrders.length > 0" class="mt-4">
      <v-col cols="12">
        <h3>Active Orders</h3>

        <order-items
          :orders="activeOrders"
          :orderSource="orderSource"
          :mobile="$vuetify.breakpoint.xs"
          status="active"
          :isChef="isChef"
        />
      </v-col>
    </v-row>

    <v-row v-if="archiveOrders.length > 0" class="mt-4">
      <v-col cols="12">
        <h3>Past orders</h3>
        <v-switch color="primary" v-model="showPastOrders" label="Show Past Orders"></v-switch>

        <order-items
          v-if="showPastOrders"
          :orders="archiveOrders"
          :orderSource="orderSource"
          status="inactive"
          :isChef="isChef"
          :mobile="$vuetify.breakpoint.xs"
        />
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col cols="12" sm="8" class="offset-sm1">
        <v-btn fab dark color="primary" @click.prevent="refresh">
          <v-icon dark>cached</v-icon>
        </v-btn>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import OrderListItems from "./OrderListItems";

export default {
  name: "OrderList",
  components: {
    "order-items": OrderListItems
  },
  data() {
    return {
      activeOnly: false,
      observer: null,
      showPastOrders: false
    };
  },
  computed: {
    ...mapGetters({
      activeOrders: "activeOrders",
      archiveOrders: "archiveOrders",
      newOrders: "newOrders",
      
      profile: "profile",
      isChef: "isChef",
      orders: "orders"
    }),
    ...mapState(["user"]),

    orderSource() {
      if (this.isChef) {
        return "seller";
      }
      return "buyer";
    }
  },
  methods: {
    view() {},
    refresh() {
      this.$store.dispatch("loadOrders").then(observer => {
        this.observer = observer;
      });
    },
    changeLeadTime() {
      this.$store.dispatch("saveSettings", {
        defaultLeadTime: parseInt(this.profile.settings.defaultLeadTime)
      });
    }
  },
  mounted() {
    // moved to main.js
    // let still call to get a fresh data
    this.$store.dispatch("loadOrders").then(observer => {
      this.observer = observer;
    });
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer();
    }
  }
};
</script>
